export function UserAvatarIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 9C12.2091 9 14 7.20914 14 5C14 2.79086 12.2091 1 10 1C7.79086 1 6 2.79086 6 5C6 7.20914 7.79086 9 10 9Z"
        fill="#87909B"
      />
      <path
        d="M2 16C2 17.6569 3.34315 19 5 19H15C16.6569 19 18 17.6569 18 16C18 14.3431 16.5 11 10 11C3.5 11 2 14.3431 2 16Z"
        fill="#87909B"
      />
    </svg>
  );
}
