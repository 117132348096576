import {type Profile} from '../../../../../types';

const getSecondaryLocations = (profile: Profile) => {
  if (
    profile?.secondaryCountries?.length === 1 &&
    profile?.secondaryCountries[0]?.name === profile?.country.name
  ) {
    return [];
  }

  if (profile?.secondaryCountries?.length >= 1) {
    const secondaryCountries = profile?.secondaryCountries?.filter(
      (country) => country.name !== profile?.country.name,
    );

    return [profile?.country, ...secondaryCountries];
  }

  return [];
};

export default getSecondaryLocations;
