import {type useField} from 'remix-validated-form';

import {useSharedTranslations} from '@/hooks/useTranslations';

interface Props {
  children: React.JSX.Element;
  handle: string;
  labelText: string | React.JSX.Element;
  field?: ReturnType<typeof useField>;
  errorId?: string;
}

export function InputWrapper({
  children,
  handle,
  labelText,
  field,
  errorId,
}: Props) {
  const {t} = useSharedTranslations('pages/partners/directory/shared');
  return (
    <div className="flex flex-col w-full mt-6">
      <label className="flex-1 w-full text-sm" htmlFor={handle}>
        {labelText}
      </label>
      {children}
      {field?.error && (
        <b className="text-xs text-red-500" id={errorId}>
          {t(field.error)}
        </b>
      )}
    </div>
  );
}
