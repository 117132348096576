export function InstagramIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#87909B" />
      <path
        d="M9.06391 5.00098H14.9439C17.1839 5.00098 19.0039 6.82098 19.0039 9.06098V14.941C19.0039 16.0178 18.5762 17.0504 17.8148 17.8118C17.0534 18.5732 16.0207 19.001 14.9439 19.001H9.06391C6.82391 19.001 5.00391 17.181 5.00391 14.941V9.06098C5.00391 7.9842 5.43166 6.95152 6.19305 6.19012C6.95445 5.42873 7.98713 5.00098 9.06391 5.00098ZM8.92391 6.40098C8.25556 6.40098 7.61459 6.66648 7.142 7.13907C6.66941 7.61166 6.40391 8.25263 6.40391 8.92098V15.081C6.40391 16.474 7.53091 17.601 8.92391 17.601H15.0839C15.7523 17.601 16.3932 17.3355 16.8658 16.8629C17.3384 16.3903 17.6039 15.7493 17.6039 15.081V8.92098C17.6039 7.52798 16.4769 6.40098 15.0839 6.40098H8.92391ZM15.6789 7.45098C15.911 7.45098 16.1335 7.54316 16.2976 7.70726C16.4617 7.87135 16.5539 8.09391 16.5539 8.32598C16.5539 8.55804 16.4617 8.7806 16.2976 8.94469C16.1335 9.10879 15.911 9.20098 15.6789 9.20098C15.4468 9.20098 15.2243 9.10879 15.0602 8.94469C14.8961 8.7806 14.8039 8.55804 14.8039 8.32598C14.8039 8.09391 14.8961 7.87135 15.0602 7.70726C15.2243 7.54316 15.4468 7.45098 15.6789 7.45098ZM12.0039 8.50098C12.9322 8.50098 13.8224 8.86973 14.4788 9.5261C15.1352 10.1825 15.5039 11.0727 15.5039 12.001C15.5039 12.9292 15.1352 13.8195 14.4788 14.4759C13.8224 15.1322 12.9322 15.501 12.0039 15.501C11.0756 15.501 10.1854 15.1322 9.52903 14.4759C8.87266 13.8195 8.50391 12.9292 8.50391 12.001C8.50391 11.0727 8.87266 10.1825 9.52903 9.5261C10.1854 8.86973 11.0756 8.50098 12.0039 8.50098ZM12.0039 9.90098C11.447 9.90098 10.9128 10.1222 10.519 10.5161C10.1252 10.9099 9.90391 11.444 9.90391 12.001C9.90391 12.5579 10.1252 13.0921 10.519 13.4859C10.9128 13.8797 11.447 14.101 12.0039 14.101C12.5609 14.101 13.095 13.8797 13.4888 13.4859C13.8827 13.0921 14.1039 12.5579 14.1039 12.001C14.1039 11.444 13.8827 10.9099 13.4888 10.5161C13.095 10.1222 12.5609 9.90098 12.0039 9.90098Z"
        fill="white"
      />
    </svg>
  );
}
