import Typography from '@/components/base/elements/Typography/Typography';

import ExpandCtaWrapper from '../ExpandCtaWrapper/ExpandCtaWrapper';
import type {Profile} from '../../../../../types';

interface Props {
  languagesExpanded: boolean;
  profile: Profile;
  setLanguagesExpanded: (value: boolean) => void;
  t: (key: string, options?: any) => string;
  languages: string;
  hasMoreLanguages: boolean;
}

const SHOWN_LANGUAGES = 3;

const Languages = ({
  languagesExpanded,
  profile,
  setLanguagesExpanded,
  t,
  languages,
  hasMoreLanguages,
}: Props) => (
  <div className="flex flex-col gap-y-1">
    <Typography as="p" size="t7">
      {t(`profilePage.profileCard.languages`)}
    </Typography>
    <Typography as="p" size="bodySm">
      {languages}
    </Typography>
    {hasMoreLanguages && (
      <ExpandCtaWrapper>
        <button
          className="text-body-small underline"
          onClick={() => setLanguagesExpanded(!languagesExpanded)}
          data-component-name="expand-languages"
        >
          {languagesExpanded
            ? t(`profilePage.profileCard.lessLanguages`)
            : t(`profilePage.profileCard.moreLanguages`, {
                count: profile.languages.length - SHOWN_LANGUAGES,
              })}
        </button>
      </ExpandCtaWrapper>
    )}
  </div>
);

export default Languages;
