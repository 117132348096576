import {useField, ValidatedForm} from 'remix-validated-form';
import {withZod} from '@remix-validated-form/with-zod';

import {useSharedTranslations} from '@/hooks/useTranslations';
import {loginFormValidator} from '@/pages/shopify.com/($locale)/partners/directory/partner/validators/loginFormValidator';

import {FormIntent} from '../../../types';
import {CloseIcon} from '../CloseIcon/CloseIcon';
import {InputWrapper} from '../InputWrapper/InputWrapper';
import {Modal} from '../Modal/Modal';

interface Props {
  isOpen: boolean;
  onClose: () => unknown;
  partnerName: string;
}

const formId = `login_email`;

export function LoginModal({isOpen, onClose, partnerName}: Props) {
  const {t} = useSharedTranslations('pages/partners/directory/shared');

  const email = useField('email', {formId});

  return (
    <Modal isOpen={isOpen} onClose={onClose} id="login-modal">
      <div className="flex justify-between gap-x-4">
        <div>
          <h2 className="text-3xl font-semibold" id="login-modal">
            {t(`profilePage.loginModal.heading`, {partnerName})}
          </h2>
          <p className="text-xl mt-2 text-gray-500">
            {t(`profilePage.loginModal.subHeading`)}
          </p>
        </div>
        <button
          onClick={onClose}
          className="w-6 h-6 flex items-center justify-center"
        >
          <CloseIcon />
        </button>
      </div>
      <ValidatedForm
        validator={withZod(loginFormValidator)}
        noValidate
        method="post"
        id={formId}
      >
        <InputWrapper
          labelText={t(`profilePage.loginModal.email.label`)}
          handle="email"
          errorId="emailErrorMessage"
          field={email}
        >
          <input
            className="flex-1 p-2 w-full rounded-sm border-2 focus:border-blue-900"
            autoFocus
            {...email.getInputProps({
              id: `email`,
              type: `email`,
              'aria-invalid': Boolean(email.error),
              ...(email.error && {
                'aria-errormessage': 'emailErrorMessage',
              }),
            })}
          />
        </InputWrapper>
        <button
          className="bg-emerald-700 cursor-pointer hover:bg-emerald-500 focus:bg-emerald-500 text-white py-2.5 px-6 rounded-full max-w-max mt-6"
          type="submit"
          name="intent"
          value={FormIntent.LOGIN}
        >
          {t(`profilePage.loginModal.submit`)}
        </button>
      </ValidatedForm>

      <p className="mt-6 text-sm">
        {t(`profilePage.loginModal.disclaimerP1`)}&nbsp;
        <a
          className="underline text-[#007A5C]"
          href="https://accounts.shopify.com/store-create"
        >
          {t(`profilePage.loginModal.disclaimerLink`)}
        </a>
        &nbsp;
        {t(`profilePage.loginModal.disclaimerP2`)}
      </p>
    </Modal>
  );
}
