import type {Profile} from '../../../../../types';

const SHOWN_LANGUAGES = 3;

const getLanguages = (languagesExpanded: boolean, profile: Profile) => {
  return (
    languagesExpanded
      ? profile?.languages
      : profile?.languages?.slice(0, SHOWN_LANGUAGES) ?? []
  )
    ?.map(({label}) => label)
    ?.join(`, `);
};

export default getLanguages;
