import Typography from '@/components/base/elements/Typography/Typography';

import {type Profile} from '../../../../../types';
import ExpandCtaWrapper from '../ExpandCtaWrapper/ExpandCtaWrapper';

const SHOWN_LOCATIONS = 4;

interface Props {
  locationsExpanded: boolean;
  secondaryLocations: Profile['secondaryCountries'];
  setLocationsExpanded: (value: boolean) => void;
  t: (key: string, options?: any) => string;
  locations: string;
  hasMoreLocations: boolean;
}

const Locations = ({
  locationsExpanded,
  secondaryLocations,
  setLocationsExpanded,
  t,
  locations,
  hasMoreLocations,
}: Props) => (
  <div className="flex flex-col gap-y-1">
    <Typography as="p" size="t7">
      {t(`profilePage.profileCard.supportedLocationsHeading`)}
    </Typography>
    {locations && (
      <Typography as="p" size="bodySm">
        {locations}
      </Typography>
    )}
    {hasMoreLocations && (
      <ExpandCtaWrapper>
        <button
          className="text-body-small underline"
          onClick={() => setLocationsExpanded(!locationsExpanded)}
          data-component-name="expand-locations"
        >
          {locationsExpanded
            ? t(`profilePage.profileCard.lessLocations`)
            : t(`profilePage.profileCard.moreLocations`, {
                count: secondaryLocations.length - SHOWN_LOCATIONS,
              })}
        </button>
      </ExpandCtaWrapper>
    )}
  </div>
);

export default Locations;
