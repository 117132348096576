import {z} from 'zod';

export const contactFormValidator = z.object({
  jobBudget: z
    .string()
    .nonempty('profilePage.contactModal.jobBudget.errors.required'),
  jobDescription: z
    .string()
    .nonempty(`profilePage.contactModal.jobDescription.errors.required`),
  merchantStoreName: z.string().nullable().optional(),
  merchantStoreUrl: z.string().nullable().optional(),
  merchantCountryCode: z
    .string()
    .nonempty('profilePage.contactModal.merchantCountryCode.errors.required'),
  merchantEmail: z
    .string()
    .nonempty('profilePage.contactModal.merchantEmail.errors.required')
    .email('profilePage.contactModal.merchantEmail.errors.invalid'),
  merchantName: z
    .string()
    .nonempty('profilePage.contactModal.merchantName.errors.required'),
  serviceHandle: z
    .string()
    .nonempty('profilePage.contactModal.serviceHandle.errors.required'),
});
