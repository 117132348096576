import {useField, useControlField} from 'remix-validated-form';

import {InputWrapper} from '../../../InputWrapper/InputWrapper';
import type {ServiceOffering} from '../../../../../types';
import type {PartnerProfileAction} from '../../../../../server/partnerProfileAction.server';
import {SelectSVG} from '../SelectSVG/SelectSVG';
import {countries} from '../../../../../countries';

interface Props {
  merchantName?: string;
  merchantEmail: string;
  shopsLoading: boolean;
  shops: any[];
  serviceOfferings: ServiceOffering[];
  submissionErrors: PartnerProfileAction['submissionErrors'];
  t: (key: string, values?: Record<string, string>) => string;
  shopsPlaceholder: string;
  partnerName: string;
}

export const FormFields = ({
  merchantName,
  merchantEmail,
  shopsLoading,
  shops,
  serviceOfferings,
  submissionErrors,
  t,
  shopsPlaceholder,
  partnerName,
}: Props) => {
  const formId = `contact`;

  const jobBudget = useField('jobBudget', {formId});
  const jobDescription = useField('jobDescription', {formId});
  const merchantStoreUrl = useField('merchantStoreUrl', {formId});
  const merchantCountryCode = useField('merchantCountryCode', {formId});
  const merchantEmailField = useField('merchantEmail', {formId});
  const merchantNameField = useField('merchantName', {formId});
  const serviceHandle = useField('serviceHandle', {formId});
  const [merchantStoreName, setMerchantStoreName] = useControlField<string>(
    'merchantStoreName',
    formId,
  );

  const jobBudgetError = jobBudget.error ?? submissionErrors?.jobBudget;
  const jobDescriptionError =
    jobDescription.error ?? submissionErrors?.jobDescription;
  const merchantCountryCodeError =
    merchantCountryCode.error ?? submissionErrors?.merchantCountryCode;
  const merchantEmailError =
    merchantEmailField.error ?? submissionErrors?.merchantEmail;
  const merchantNameError =
    merchantNameField.error ?? submissionErrors?.merchantName;
  const serviceHandleError =
    serviceHandle.error ?? submissionErrors?.serviceHandle;

  return (
    <>
      <InputWrapper
        labelText={t(`profilePage.contactModal.merchantName.label`)}
        handle="merchantName"
        errorId="merchantNameErrorMessage"
        field={{
          ...merchantNameField,
          error: merchantNameError,
        }}
      >
        <input
          className="flex-1 p-2 w-full rounded-sm border-2 focus:border-blue-900"
          defaultValue={merchantName ?? ``}
          autoFocus
          {...merchantNameField.getInputProps({
            id: `merchantName`,
            type: `text`,
            'aria-invalid': Boolean(merchantNameError),
            ...(merchantNameError && {
              'aria-errormessage': 'merchantNameErrorMessage',
            }),
          })}
        />
      </InputWrapper>
      <InputWrapper
        labelText={t(`profilePage.contactModal.merchantEmail.label`)}
        handle="merchantEmail"
        errorId="merchantEmailErrorMessage"
        field={{...merchantEmailField, error: merchantEmailError}}
      >
        <input
          className="flex-1 p-2 mt-1 w-full rounded-sm border-2 focus:border-blue-900"
          defaultValue={merchantEmail ?? ``}
          {...merchantEmailField.getInputProps({
            id: `merchantEmail`,
            type: `email`,
            'aria-invalid': Boolean(merchantEmailError),
            ...(merchantEmailError && {
              'aria-errormessage': 'merchantEmailErrorMessage',
            }),
          })}
        />
      </InputWrapper>
      <InputWrapper
        labelText={t(`profilePage.contactModal.merchantStoreDetails.label`)}
        handle="merchantStoreUrl"
      >
        <div className="flex-1 mt-1 w-full flex relative">
          <select
            className="flex-1 p-2 w-full rounded-sm border-2 focus:border-blue-900 appearance-none relative enabled:cursor-pointer disabled:cursor-not-allowed"
            disabled={shopsLoading || shops?.length === 0}
            {...merchantStoreUrl.getInputProps({
              id: `merchantStoreUrl`,
              onChange: (e) =>
                setMerchantStoreName(
                  shops.find((store) => store.value === e.target.value)
                    ?.label ?? ``,
                ),
            })}
          >
            <option value="">{t(shopsPlaceholder)}</option>
            {shops.map((shop) => (
              <option key={shop.value} value={shop.value}>
                {shop.label}
              </option>
            ))}
          </select>
          <input
            type="hidden"
            name="merchantStoreName"
            value={merchantStoreName}
          />
          <SelectSVG />
        </div>
      </InputWrapper>
      <InputWrapper
        labelText={t(`profilePage.contactModal.merchantCountryCode.label`)}
        handle="merchantCountryCode"
        errorId="merchantCountryCodeErrorMessage"
        field={{
          ...merchantCountryCode,
          error: merchantCountryCodeError,
        }}
      >
        <div className="flex-1 mt-1 w-full flex relative">
          <select
            className="flex-1 p-2 w-full rounded-sm border-2 focus:border-blue-900 appearance-none relative cursor-pointer"
            {...merchantCountryCode.getInputProps({
              id: `merchantCountryCode`,
              'aria-invalid': Boolean(merchantCountryCodeError),
              ...(merchantCountryCodeError && {
                'aria-errormessage': 'merchantCountryCodeErrorMessage',
              }),
            })}
          >
            <option value="">
              {t(`profilePage.contactModal.merchantCountryCode.placeholder`)}
            </option>
            {countries.map(({value, name}: {value: string; name: string}) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </select>
          <SelectSVG />
        </div>
      </InputWrapper>
      <InputWrapper
        labelText={t(`profilePage.contactModal.serviceHandle.label`, {
          partnerName,
        })}
        handle="serviceHandle"
        errorId="serviceHandleErrorMessage"
        field={{...serviceHandle, error: serviceHandleError}}
      >
        <div className="flex-1 mt-1 w-full flex relative">
          <select
            className="flex-1 p-2 w-full rounded-sm border-2 focus:border-blue-900 appearance-none relative cursor-pointer"
            key="serviceHandle"
            {...serviceHandle.getInputProps({
              id: `serviceHandle`,
              'aria-invalid': Boolean(serviceHandleError),
              ...(serviceHandleError && {
                'aria-errormessage': 'serviceHandleErrorMessage',
              }),
            })}
          >
            <option value="">
              {t(`profilePage.contactModal.serviceHandle.placeholder`)}
            </option>
            {serviceOfferings.map((serviceOffering) => (
              <option
                key={serviceOffering.service.handle}
                value={serviceOffering.service.handle}
              >
                {serviceOffering.service.label}
              </option>
            ))}
          </select>
          <SelectSVG />
        </div>
      </InputWrapper>
      <InputWrapper
        labelText={t(`profilePage.contactModal.jobBudget.label`)}
        handle="jobBudget"
        errorId="jobBudgetErrorMessage"
        field={{...jobBudget, error: jobBudgetError}}
      >
        <input
          className="flex-1 p-2 mt-1 w-full rounded-sm border-2 focus:border-blue-900"
          {...jobBudget.getInputProps({
            id: `jobBudget`,
            type: `text`,
            'aria-invalid': Boolean(jobBudgetError),
            ...(jobBudgetError && {
              'aria-errormessage': 'jobBudgetErrorMessage',
            }),
          })}
        />
      </InputWrapper>
      <InputWrapper
        labelText={t(`profilePage.contactModal.jobDescription.label`)}
        handle="jobDescription"
        errorId="jobDescriptionErrorMessage"
        field={{...jobDescription, error: jobDescriptionError}}
      >
        <textarea
          {...jobDescription.getInputProps({
            id: `jobDescription`,
            'aria-invalid': Boolean(jobDescriptionError),
            ...(jobDescriptionError && {
              'aria-errormessage': 'jobBudgetErrorMessage',
            }),
          })}
          rows={5}
          className="flex-1 p-2 mt-1 w-full rounded-sm border-2 focus:border-blue-900"
        ></textarea>
      </InputWrapper>
    </>
  );
};
