interface Props {
  className?: string;
}

export function ExternalLinkIcon({className}: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ``}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 4C11 3.44772 11.4477 3 12 3H16C16.5523 3 17 3.44772 17 4V8C17 8.55228 16.5523 9 16 9C15.4477 9 15 8.55228 15 8V6.41421L9.70711 11.7071C9.31658 12.0976 8.68342 12.0976 8.29289 11.7071C7.90237 11.3166 7.90237 10.6834 8.29289 10.2929L13.5858 5H12C11.4477 5 11 4.55228 11 4ZM3 6.5C3 5.67157 3.67157 5 4.5 5H8C8.55228 5 9 5.44772 9 6C9 6.55228 8.55228 7 8 7H5V15H13V12C13 11.4477 13.4477 11 14 11C14.5523 11 15 11.4477 15 12V15.5C15 16.3284 14.3284 17 13.5 17H4.5C3.67157 17 3 16.3284 3 15.5V6.5Z"
        fill="#616A75"
      />
    </svg>
  );
}
