import Section from '@/components/base/layouts/Section/Section';
import {TwoColumn} from '@/components/base/layouts/TwoColumn/TwoColumn';
import Typography from '@/components/base/elements/Typography/Typography';
import Image from '@/components/base/elements/Image/Image';

import type {Profile} from '../../../types';
import PartnersDirectoryPageLayout from '../../../../components/PageLayout/PageLayout';

interface Props {
  merchantEmail?: string;
  profile?: Profile;
  t: (key: string, options?: Record<string, unknown>) => string;
}

export default function ErrorPage({merchantEmail, profile, t}: Props) {
  return (
    <PartnersDirectoryPageLayout
      user={merchantEmail ? {email: merchantEmail} : undefined}
      title={t('htmlHead.partnerTitle', {
        partnerName: profile?.name,
      })}
      secondaryNavPageTitle={t('secondaryNav.profile')}
    >
      <Section className="grid gap-y-2xl text-section-light-text !py-0 bg-white">
        <TwoColumn className="pt-16 pb-4 md:py-20">
          <TwoColumn.Col1 className="flex flex-col col-span-12 md:col-span-7 gap-y-md justify-start my-auto">
            <Typography as="h1" size="t4">
              {t('errorPage.heading')}
            </Typography>
            <Typography
              as="p"
              className="text-[1.375rem] leading-8 text-[#52525B]"
            >
              {t('errorPage.subHeadingA')}{' '}
              <a
                href="https://www.shopifystatus.com/"
                className="hover:underline text-black"
              >
                {t('errorPage.statusPageLinkText')}
              </a>{' '}
              {t('errorPage.subHeadingB')}
            </Typography>
          </TwoColumn.Col1>
          <TwoColumn.Col2 className="sm:col-start-1 sm:col-span-8 my-auto pb-2xl md:pb-0 col-span-3 col-start-9">
            <Image
              className="w-[21.75rem] h-[19.75] ml-12"
              src="https://cdn.shopify.com/b/shopify-brochure2-assets/5a03706b5da8ba5c5f7892a71c40b776.png?originalWidth=348&originalHeight=316"
              alt={t('errorPage.imageAltText')}
            />
          </TwoColumn.Col2>
        </TwoColumn>
      </Section>
    </PartnersDirectoryPageLayout>
  );
}
