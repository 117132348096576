import {useCallback, useEffect, useRef} from 'react';

import {useFocusTrap} from '@/hooks/useFocusTrap';

interface Props {
  children: React.JSX.Element | React.JSX.Element[];
  isOpen: boolean;
  onClose: () => unknown;
  id: string;
}

export function Modal({children, isOpen, onClose, id}: Props) {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const modalRef = useRef<HTMLElement | null>(null);

  useFocusTrap(modalRef, isOpen);

  const clickOutsideListener = useCallback((e: MouseEvent) => {
    if (wrapperRef?.current === e.target) {
      onClose();
    }
    // Only needs to be instantiated once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const keyboardListener = useCallback((e: KeyboardEvent) => {
    if (e.key === `Escape`) {
      onClose();
    }
    // Only needs to be instantiated once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const yOffset = modalRef?.current?.getBoundingClientRect?.()?.top ?? NaN;
    const scrollOffset = yOffset - 50;
    if (window.scrollY > scrollOffset) {
      window.scrollTo({
        top: scrollOffset,
        left: 0,
        behavior: `smooth`,
      });
    }

    window.addEventListener('click', clickOutsideListener);
    window.addEventListener('keydown', keyboardListener);

    return () => {
      window.removeEventListener(`click`, clickOutsideListener);
      window.removeEventListener(`keydown`, keyboardListener);
    };
    // Only needs to be instantiated once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="absolute w-full flex justify-center top-0 mt-0 md:mt-20">
      <div className="bg-white rounded-none min-[621px]:rounded-md shadow-lg max-w-[38.75rem] w-full z-[1000] p-12">
        <section
          className="flex flex-col"
          ref={modalRef}
          role="dialog"
          aria-labelledby={id}
        >
          {children}
        </section>
      </div>
      <div
        className="fixed inset-0 w-full h-full bg-slate-900 opacity-50 z-modal"
        ref={wrapperRef}
      ></div>
    </div>
  );
}
