export function RatingsBar({percentage}: {percentage: number}) {
  return (
    <div className="flex relative overflow-hidden rounded-md bg-gray-200 h-4">
      <div
        className="absolute top-0 bottom-10 left-0  bg-emerald-200 h-4"
        style={{paddingRight: `${percentage || 0}%`}}
      ></div>
    </div>
  );
}
