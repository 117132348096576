import {useSharedTranslations} from '@/hooks/useTranslations';

interface Props {
  dismiss: () => void;
  message: string;
}

export function ErrorBanner({dismiss, message}: Props) {
  const {t} = useSharedTranslations('pages/partners/directory/shared');
  return (
    <div
      className="p-5 mt-6 border border-[#F56B52] rounded bg-[#FDE2DD] flex gap-4 items-start"
      role="alertdialog"
      aria-labelledby="contact-modal-error-banner"
    >
      <svg
        className="mt-1"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7674 0.767389C10.7911 -0.208922 9.20816 -0.208921 8.23185 0.76739L0.767389 8.23185C-0.208922 9.20817 -0.208921 10.7911 0.76739 11.7674L8.23185 19.2319C9.20817 20.2082 10.7911 20.2082 11.7674 19.2319L19.2319 11.7674C20.2082 10.7911 20.2082 9.20816 19.2319 8.23185L11.7674 0.767389ZM8.99956 5.99962C8.99956 5.44734 9.44728 4.99962 9.99956 4.99962C10.5518 4.99962 10.9996 5.44734 10.9996 5.99962V9.99962C10.9996 10.5519 10.5518 10.9996 9.99956 10.9996C9.44728 10.9996 8.99956 10.5519 8.99956 9.99962V5.99962ZM10.9996 13.9996C10.9996 14.5519 10.5518 14.9996 9.99956 14.9996C9.44728 14.9996 8.99956 14.5519 8.99956 13.9996C8.99956 13.4473 9.44728 12.9996 9.99956 12.9996C10.5518 12.9996 10.9996 13.4473 10.9996 13.9996Z"
          fill="#C5280C"
        />
      </svg>
      <div className="flex-1">
        <h3 className="xs:text-base md:text-lg" id="contact-modal-error-banner">
          <strong>
            {t(`profilePage.contactModal.errors.generalError.heading`)}
          </strong>
        </h3>
        <p className="xs:text-sm md:text-base">{message}</p>
      </div>
      <button
        aria-label={t(`profilePage.contactModal.errors.dismiss.aria`)}
        className="p-3 -mt-1"
        onClick={dismiss}
      >
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L3.58579 5L0.292893 8.29289C-0.0976311 8.68342 -0.0976311 9.31658 0.292893 9.70711C0.683417 10.0976 1.31658 10.0976 1.70711 9.70711L5 6.41421L8.29289 9.70711C8.68342 10.0976 9.31658 10.0976 9.70711 9.70711C10.0976 9.31658 10.0976 8.68342 9.70711 8.29289L6.41421 5L9.70711 1.70711C10.0976 1.31658 10.0976 0.683417 9.70711 0.292893C9.31658 -0.0976311 8.68342 -0.0976311 8.29289 0.292893L5 3.58579L1.70711 0.292893Z"
            fill="#616A75"
          />
        </svg>
      </button>
    </div>
  );
}
