import {useState} from 'react';

import {useSharedTranslations} from '@/hooks/useTranslations';
import Typography from '@/components/base/elements/Typography/Typography';

import type {ServiceOffering} from '../../types';

interface Props {
  otherServices: ServiceOffering[];
}

const SHOWN_OTHER_SERVICES = 6;

export function OtherServices({otherServices}: Props) {
  const {t} = useSharedTranslations('pages/partners/directory/shared');
  const [otherServicesExpanded, setOtherServicesExpanded] = useState(false);

  const slicedOtherServices = otherServicesExpanded
    ? otherServices
    : otherServices.slice(0, SHOWN_OTHER_SERVICES);

  return (
    <div className="py-4">
      <Typography as="h2" size="t6" className="pb-2">
        {t(`profilePage.otherServices.heading`)}
      </Typography>
      <Typography as="p" className="opacity-70">
        {slicedOtherServices
          .map((serviceOffering) => serviceOffering.service.label)
          .join(`, `)}
      </Typography>
      {otherServices.length > SHOWN_OTHER_SERVICES && (
        <button
          onClick={() =>
            setOtherServicesExpanded((prevExpanded) => !prevExpanded)
          }
          className="underline text-left text-body-base hover:no-underline focus:outline-state-focus opacity-70 mt-1"
        >
          {t(
            `profilePage.otherServices.${
              otherServicesExpanded ? `lessServices` : `moreServices`
            }`,
          )}
        </button>
      )}
    </div>
  );
}
