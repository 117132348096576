import Typography from '@/components/base/elements/Typography/Typography';
import {useSharedTranslations} from '@/hooks/useTranslations';

import type {Industry} from '../../types';

interface Props {
  industries: Industry[];
}

export function Industries({industries}: Props) {
  const {t} = useSharedTranslations('pages/partners/directory/shared');
  return (
    <div className="py-4">
      <Typography as="h2" size="t6" className="pb-2">
        {t(`profilePage.industries.heading`)}
      </Typography>
      <Typography as="p" className="opacity-70">
        {industries?.map((industry) => industry.label).join(', ')}
      </Typography>
    </div>
  );
}
