export function LinkedInIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#87909B" />
      <path
        d="M18 13.0734V18.25H15.4846V13.4348C15.4846 13.0722 15.3516 12.7244 15.1149 12.4679C14.8782 12.2115 14.5571 12.0674 14.2224 12.0674C13.5282 12.0674 12.9692 12.6828 12.9692 13.4348V18.25H10.4538V10.0749H12.9692V11.1591C13.402 10.3973 14.2945 9.88937 15.0609 9.88937C15.8404 9.88937 16.5879 10.2248 17.1391 10.822C17.6903 11.4191 18 12.229 18 13.0734Z"
        fill="white"
      />
      <path
        d="M8.59469 8.06091C8.31063 8.36863 7.92538 8.54151 7.52367 8.54151C6.6852 8.54151 6 7.80898 6 6.90064C6 6.46286 6.16053 6.04302 6.44627 5.73346C6.73201 5.42391 7.11957 5.25 7.52367 5.25C8.36213 5.25 9.03832 5.9923 9.03832 6.90064C9.03832 7.33582 8.87874 7.75319 8.59469 8.06091Z"
        fill="white"
      />
      <path d="M8.77686 10.0749V18.25H6.27949V10.0749H8.77686Z" fill="white" />
    </svg>
  );
}
