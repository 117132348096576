import {useEffect, useRef, useState} from 'react';
import {
  useActionData,
  useFetcher,
  useLoaderData,
  useNavigation,
  useSearchParams,
} from '@remix-run/react';
import type {HeadersFunction} from '@remix-run/cloudflare';

import type {Handle} from '@/types';
import {useSharedTranslations} from '@/hooks/useTranslations';
import Grid, {Col} from '@/components/base/layouts/utils/Grid/Grid';
import Section from '@/components/base/layouts/Section/Section';
import Typography from '@/components/base/elements/Typography/Typography';
import {ContactModal} from '@/pages/shopify.com/($locale)/partners/directory/partner/$partner/components/ContactModal/ContactModal';
import {LoginModal} from '@/pages/shopify.com/($locale)/partners/directory/partner/$partner/components/LoginModal/LoginModal';
import {ProfileCard} from '@/pages/shopify.com/($locale)/partners/directory/partner/$partner/components/ProfileCard/ProfileCard';
import {DIRECTORY_CACHE_TTL} from '@/pages/shopify.com/($locale)/partners/directory/utilities/constants';

import PartnersDirectoryPageLayout from '../../components/PageLayout/PageLayout';
import {
  type PartnerProfileLoader,
  partnerProfileLoader as loader,
} from '../server/partnerProfileLoader.server';
import {FormIntent} from '../types';
import {type PartnerProfileAction} from '../server/partnerProfileAction.server';

import {Rating} from './components/Rating/Rating';
import {Reviews} from './components/Reviews/Reviews';
import ErrorPage from './components/ErrorPage/ErrorPage';
import {SpecializedServices} from './components/SpecializedServices';
import {OtherServices} from './components/OtherServices';
import {FeaturedWorks} from './components/FeaturedWorks';
import {Industries} from './components/Industries';

export const handle: Handle = {
  mainClass: 'pt-global-header-with-subnav',
  shareImage:
    'https://cdn.shopify.com/b/shopify-brochure2-assets/b659c83a85f891274ca196187bbb8904.png?originalWidth=1200&originalHeight=630',
  metadata: {
    pageGroup: `partners`,
    pagePath: `/partners/directory/partner/$partner`,
  },
};

export const headers: HeadersFunction = () => ({
  'Cache-Control': `max-age=${DIRECTORY_CACHE_TTL}`,
});

export {loader};
export {partnerProfileAction as action} from '../server/partnerProfileAction.server';

/**
 * Our version of remix has type inference issues with loaders & actions
 * Manually typing for now
 *
 * @see https://github.com/remix-run/remix/issues/4529
 */

export default function PartnersDirectoryListing() {
  const loaderData = useLoaderData<PartnerProfileLoader>();
  const {
    submit,
    data = {},
    state,
  }: {
    submit: any;
    data: any;
    state: 'idle' | 'loading' | 'submitting';
  } = useFetcher();
  const navigation = useNavigation();
  const actionData = useActionData<PartnerProfileAction>();
  const [params] = useSearchParams();
  const [showModal, setShowModal] = useState(Boolean(params.get(`contact`)));
  const {t} = useSharedTranslations('pages/partners/directory/shared');
  const modalOpenerRef = useRef<HTMLButtonElement | null>(null);

  let hasCalledForShops = useRef(false);

  const showModalFunc = () => setShowModal(true);
  const hideModalFunc = () => {
    setShowModal(false);
    modalOpenerRef?.current?.focus?.();
  };

  const profile = loaderData?.profile;
  const specializedServices = loaderData?.specializedServices;
  const otherServices = loaderData?.otherServices;
  const featuredWorks = profile?.featuredWorks;
  const merchantName = loaderData?.merchantName;
  const merchantEmail = loaderData?.merchantEmail;
  const error = loaderData?.error;

  useEffect(() => {
    window?.scrollTo(0, 0);
  }, [navigation.state]);

  useEffect(() => {
    if (merchantEmail && !hasCalledForShops.current) {
      submit({intent: FormIntent.FETCH_SHOPS}, {method: 'POST'});
      hasCalledForShops.current = true;
    }
    // We only want this re-calculating if merchantEmail changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantEmail]);

  if (error) {
    return <ErrorPage merchantEmail={merchantEmail} profile={profile} t={t} />;
  }

  return (
    <PartnersDirectoryPageLayout
      user={merchantEmail ? {email: merchantEmail} : undefined}
      title={t('htmlHead.partnerTitle', {
        partnerName: profile?.name,
      })}
      secondaryNavPageTitle={t('secondaryNav.profile')}
    >
      <Grid>
        <Col span={{sm: 8, md: 5}}>
          <ProfileCard
            modalOpenerRef={modalOpenerRef}
            profile={profile}
            showModalFunc={showModalFunc}
          />
        </Col>
        <Col start={{md: 7}} span={{sm: 8, md: 7}}>
          <Section
            topSpacing="2xl"
            bottomSpacing="2xl"
            className="xs:pt-12 md:pt-0 md:mt-32 xs:mt-6 pb-8 border-t xs:border-subdued-light md:border-none"
            sectionName="description"
          >
            <div className="flex flex-col xs:-mt-6 md:mt-0">
              <Typography as="h2" size="t5" className="pb-1">
                {t(`profilePage.about.heading`)}
              </Typography>
              <Typography as="h3" size="t6" className="pt-4 pb-2">
                {t(`profilePage.businessDescription.heading`)}
              </Typography>
              <pre className="text-body-base font-sans whitespace-pre-wrap opacity-70 pb-4">
                {profile?.bio}
              </pre>
              {specializedServices?.length >= 1 && (
                <SpecializedServices
                  specializedServices={specializedServices}
                />
              )}
              {otherServices?.length >= 1 && (
                <OtherServices otherServices={otherServices} />
              )}
              {profile?.industries?.length > 0 && (
                <Industries industries={profile.industries} />
              )}
              {featuredWorks?.length >= 1 && (
                <FeaturedWorks featuredWorks={featuredWorks} />
              )}
            </div>
          </Section>
          <Section
            topSpacing="2xl"
            bottomSpacing="2xl"
            sectionName="rating"
            className="py-8 border-t xs:border-subdued-light gap-y-4"
          >
            {profile.numberOfRatings >= 1 ? (
              <>
                <Rating profile={profile} />
                <Reviews
                  fetcherReviews={data?.reviews}
                  profile={profile}
                  submit={submit}
                  fetcherState={state}
                />
              </>
            ) : (
              <Typography as="h3" className="min-h-60" size="t7">
                {t(`profilePage.noReviews`)}
              </Typography>
            )}
          </Section>
        </Col>
      </Grid>
      {showModal && merchantEmail && (
        <ContactModal
          actionData={actionData}
          isOpen={showModal}
          onCancel={hideModalFunc}
          merchantName={merchantName}
          merchantEmail={merchantEmail}
          partnerName={profile?.name || ''}
          serviceOfferings={[...otherServices, ...specializedServices]}
          shops={data?.shops ?? []}
          shopsLoading={
            state === `loading` ||
            state === `submitting` ||
            (Boolean(merchantEmail) && !hasCalledForShops.current)
          }
        />
      )}
      {showModal && !merchantEmail && (
        <LoginModal
          isOpen={showModal}
          onClose={hideModalFunc}
          partnerName={profile?.name ?? ``}
        />
      )}
    </PartnersDirectoryPageLayout>
  );
}
