import {useCallback, useEffect, useRef, useState} from 'react';
import {ValidatedForm, useIsSubmitting} from 'remix-validated-form';
import {withZod} from '@remix-validated-form/with-zod';

import {useSharedTranslations} from '@/hooks/useTranslations';
import {contactFormValidator} from '@/pages/shopify.com/($locale)/partners/directory/partner/validators/contactFormValidator';
import {type PartnerProfileAction} from '@/pages/shopify.com/($locale)/partners/directory/partner/server/partnerProfileAction.server';

import {type ServiceOffering} from '../../../types';
import {CloseIcon} from '../CloseIcon/CloseIcon';
import {Modal} from '../Modal/Modal';

import {ErrorBanner} from './components/ErrorBanner/ErrorBanner';
import {FormFields} from './components/FormFields/FormFields';

interface Props {
  actionData: PartnerProfileAction | undefined;
  isOpen: boolean;
  merchantName?: string;
  merchantEmail: string;
  onCancel: () => unknown;
  partnerName: string;
  serviceOfferings: ServiceOffering[];
  shops: any[];
  shopsLoading: boolean;
}

const formId = `contact`;

export function ContactModal({
  actionData,
  isOpen,
  merchantName,
  merchantEmail,
  onCancel,
  partnerName,
  serviceOfferings,
  shops,
  shopsLoading,
}: Props) {
  const shopsLoadingTimeout = useRef<ReturnType<typeof setTimeout>>();
  const isSubmitting = useIsSubmitting(formId);
  const {t} = useSharedTranslations('pages/partners/directory/shared');
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [shopsPlaceholder, setShopsPlaceholder] = useState(
    shopsLoading
      ? `profilePage.contactModal.merchantStoreDetails.loading`
      : `profilePage.contactModal.merchantStoreDetails.placeholder`,
  );

  const {submissionErrors, authErrors, gqlError, jsonError, userErrors} =
    actionData ?? {};

  const isLoading =
    isSubmitting &&
    !authErrors &&
    !gqlError &&
    !jsonError &&
    !submissionErrors &&
    !userErrors &&
    !actionData?.success;

  const bannerText = (): string => {
    if (authErrors || gqlError || jsonError) {
      return t(`profilePage.contactModal.errors.generalError.message`);
    }

    return (
      userErrors?.[0]?.message ??
      t(`profilePage.contactModal.errors.generalError.message`)
    );
  };

  const updateShopsPlaceholder = useCallback(() => {
    setShopsPlaceholder((prevPlaceholder) => {
      const trailingPeriods = t(prevPlaceholder).split(`.`).length - 1;
      const newTrailingPeriods = trailingPeriods + 1;

      if (newTrailingPeriods > 3) {
        return `profilePage.contactModal.merchantStoreDetails.loading`;
      }

      return `profilePage.contactModal.merchantStoreDetails.loading${newTrailingPeriods}`;
    });

    shopsLoadingTimeout.current = setTimeout(updateShopsPlaceholder, 750);
    // This is a useEffect dep that recalculates lazily, leaving the deps empty so it doesn't retrigger the useEffect needlessly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shopsLoading) {
      shopsLoadingTimeout.current = setTimeout(updateShopsPlaceholder, 750);
    } else {
      clearTimeout(shopsLoadingTimeout?.current);
      setShopsPlaceholder(
        `profilePage.contactModal.merchantStoreDetails.placeholder`,
      );
    }

    return () => {
      clearTimeout(shopsLoadingTimeout?.current);
    };
    // shopsLoading changing is the only thing we want to trigger this effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopsLoading]);

  useEffect(() => {
    const shouldShowErrorBanner =
      (actionData?.authErrors ||
        actionData?.gqlError ||
        actionData?.jsonError ||
        actionData?.userErrors) &&
      !actionData?.success &&
      !showErrorBanner;

    if (shouldShowErrorBanner !== showErrorBanner) {
      setShowErrorBanner(Boolean(shouldShowErrorBanner));
    }
    // We're missing 'showErrorBanner' but we don't want to re-run this when that value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actionData?.authErrors,
    actionData?.gqlError,
    actionData?.jsonError,
    actionData?.userErrors,
    actionData?.success,
  ]);

  return (
    <Modal isOpen={isOpen} onClose={onCancel} id="contact-modal">
      <div className="flex-1 flex justify-between">
        <div className="flex-1">
          <h2 className="text-3xl font-semibold" id="contact-modal">
            {actionData?.success
              ? t(`profilePage.contactModal.submitted.title`)
              : t(`profilePage.contactModal.title`, {partnerName})}
          </h2>
          <p className="text-xl mt-2 text-gray-500">
            {actionData?.success
              ? t(`profilePage.contactModal.submitted.description`)
              : t(`profilePage.contactModal.description`, {partnerName})}
          </p>
        </div>
        <button
          onClick={onCancel}
          className="flex-none w-6 h-6 flex items-center"
        >
          <CloseIcon />
        </button>
      </div>
      {!actionData?.success ? (
        <ValidatedForm
          validator={withZod(contactFormValidator)}
          noValidate
          method="post"
          id="contact"
        >
          <FormFields
            merchantEmail={merchantEmail}
            shopsLoading={shopsLoading}
            shops={shops}
            serviceOfferings={serviceOfferings}
            merchantName={merchantName}
            submissionErrors={submissionErrors}
            t={t}
            shopsPlaceholder={shopsPlaceholder}
            partnerName={partnerName}
          />
          <p className="text-xs text-gray-500 mt-6">
            {t(`profilePage.contactModal.disclaimer`)}
          </p>
          {showErrorBanner && (
            <ErrorBanner
              dismiss={() => setShowErrorBanner(false)}
              message={bannerText()}
            />
          )}
          <fieldset className="flex w-full mt-6 justify-end">
            <button
              className="flex-auto py-3 px-6 mr-3 max-w-max text-gray-500"
              onClick={onCancel}
            >
              {t(`profilePage.contactModal.cancel`)}
            </button>
            <button
              className="flex-auto bg-emerald-700 hover:bg-emerald-500 focus:bg-emerald-500 text-white py-2.5 px-[1rem] rounded-full max-w-max enabled:cursor-pointer disabled:cursor-not-allowed"
              type="submit"
              disabled={isLoading}
              name="intent"
              value={formId}
            >
              {t(`profilePage.contactModal.submit`)}
            </button>
          </fieldset>
        </ValidatedForm>
      ) : (
        <></>
      )}
    </Modal>
  );
}
