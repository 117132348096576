export function TwitterIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#87909B" />
      <g clip-path="url(#clip0_4306_125844)">
        <path
          d="M13.1416 11.1928L17.6089 6H16.5503L12.6714 10.5089L9.57328 6H6L10.6849 12.8182L6 18.2637H7.05866L11.1549 13.5022L14.4267 18.2637H18L13.1414 11.1928H13.1416ZM11.6916 12.8783L11.217 12.1993L7.44011 6.79694H9.06615L12.1141 11.1569L12.5888 11.8358L16.5508 17.503H14.9248L11.6916 12.8785V12.8783Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4306_125844">
          <rect
            width="12"
            height="12.27"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
