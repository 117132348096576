import {type MutableRefObject, useState} from 'react';

import {twMerge} from '@/stylesheets/twMerge';
import Section from '@/components/base/layouts/Section/Section';
import Typography from '@/components/base/elements/Typography/Typography';
import {useSharedTranslations} from '@/hooks/useTranslations';
import {buttonStyles} from '@/components/base/elements/Button/styles';

import {Badge} from '../../../../components/Badge/Badge';
import {type Profile, Tags} from '../../../types';
import {SHOW_NEW_BADGE_MONTHS} from '../../../../utilities/constants';
import {ProfileTag} from '../../../../components/ProfileTag/ProfileTag';
import {EmailIcon} from '../EmailIcon/EmailIcon';
import {PhoneIcon} from '../PhoneIcon/PhoneIcon';
import {WebsiteIcon} from '../WebsiteIcon/WebsiteIcon';

import simplifyUrl from './helpers/simplifyUrl/simplifyUrl';
import getSecondaryLocations from './helpers/getSecondaryLocations/getSecondaryLocations';
import getLocations from './helpers/getLocations/getLocations';
import getLanguages from './helpers/getLanguages/getLanguages';
import ContactFieldWrapper from './components/ContactFieldWrapper/ContactFieldWrapper';
import Languages from './components/Languages/Languages';
import Locations from './components/Locations/Locations';
import SocialMediaLinks from './components/SocialMediaLinks/SocialMediaLinks';
import {Ratings} from './components/Ratings';
import {NewBadge} from './components/NewBadge';

interface Props {
  modalOpenerRef: MutableRefObject<HTMLButtonElement | null>;
  profile: Profile;
  showModalFunc: () => void;
}

const SHOWN_LANGUAGES = 3;
const SHOWN_LOCATIONS = 4;

export function ProfileCard({profile, modalOpenerRef, showModalFunc}: Props) {
  const [locationsExpanded, setLocationsExpanded] = useState(false);
  const [languagesExpanded, setLanguagesExpanded] = useState(false);
  const {t} = useSharedTranslations('pages/partners/directory/shared');

  const secondaryLocations = getSecondaryLocations(profile);

  const hasSecondaryLocations = secondaryLocations.length > 0;
  const hasMoreLocations = secondaryLocations.length > SHOWN_LOCATIONS;
  const hasMoreLanguages = profile?.languages?.length > SHOWN_LANGUAGES;

  const socialMediaLinks =
    profile?.socialMediaLinks?.filter?.((link) => Boolean(link.url)) ?? [];

  const locations = getLocations(locationsExpanded, secondaryLocations);

  const languages = getLanguages(languagesExpanded, profile);

  const showNewBadge = () => {
    let createdAt;

    try {
      createdAt = new Date(profile.createdAt);
    } catch (_error) {
      // no need to rescue this edge-case, just show Reviews instead
      return false;
    }

    const showNewBadgeMin = new Date().setMonth(
      new Date().getMonth() - SHOW_NEW_BADGE_MONTHS,
    );

    return (
      createdAt.getTime() > showNewBadgeMin &&
      createdAt.getTime() < new Date().getTime() &&
      !profile.numberOfRatings
    );
  };
  const plusTag = profile.tags?.includes(Tags.PLUS_TAG) ? Tags.PLUS_TAG : null;

  return (
    <Section
      topSpacing="2xl"
      bottomSpacing="2xl"
      sectionName="profile"
      className="md:translate-y-16 xs:translate-y-0 pb-0 xs:-mb-10 md:mb-0"
    >
      <div className="flex flex-col gap-y-5 relative md:rounded-lg xs:pt-0 md:pt-24 md:px-6 md:pb-6 md:shadow-light">
        <div className="gap-y-4 flex flex-col">
          {plusTag && (
            <div className="top-3 right-3 absolute">
              <ProfileTag tag={plusTag} />
            </div>
          )}
          <div className="grid gap-y-3">
            <Typography as="h1" size="t4">
              {profile?.name}
            </Typography>
            {showNewBadge() && <NewBadge profile={profile} />}
            {profile?.acceptingClients && !showNewBadge() && (
              <Badge>{t('listing.profileCard.newClientsBadge')}</Badge>
            )}
          </div>
          {profile?.averageRating &&
            profile?.numberOfRatings &&
            !showNewBadge() && <Ratings profile={profile} />}
          {profile?.acceptingClients && (
            <button
              className={twMerge(
                buttonStyles({
                  intent: undefined,
                  mode: undefined,
                  size: undefined,
                }),
                'w-full bg-black enabled:cursor-pointer disabled:cursor-not-allowed disabled:bg-[#EBECEF]',
              )}
              type={undefined}
              onClick={showModalFunc}
              ref={modalOpenerRef}
            >
              {t(`profilePage.profileCard.contact`)}
            </button>
          )}
        </div>

        <div className="order-first md:order-none md:absolute rounded-full overflow-hidden w-20 h-20 md:w-40 md:h-40 md:top-0 md:left-6 md:-translate-y-1/2 shadow-md">
          <img
            className="h-full w-full object-cover"
            alt={profile?.name ?? `Profile picture`}
            src={profile?.avatarUrl}
          />
        </div>

        <hr className="bt border-subdued-light" />
        <div className="flex flex-col gap-y-1">
          <Typography as="p" size="t7">
            {t(`profilePage.profileCard.contactInformationHeading`)}
          </Typography>
          <div className="flex flex-col gap-y-3">
            {profile?.websiteUrl && (
              <ContactFieldWrapper
                icon={
                  <a
                    className="hover:underline focus:underline"
                    href={profile.websiteUrl}
                    rel="nofollow"
                  >
                    <WebsiteIcon />
                  </a>
                }
                copy={
                  <a
                    href={profile.websiteUrl}
                    className="hover:underline focus:underline"
                    rel="nofollow"
                  >
                    {simplifyUrl(profile.websiteUrl)}
                  </a>
                }
              />
            )}
            {profile?.contactPhoneNumber && (
              <ContactFieldWrapper
                icon={
                  <a
                    className="hover:underline focus:underline"
                    href={`tel:${profile.contactPhoneNumber}`}
                  >
                    <PhoneIcon />
                  </a>
                }
                copy={
                  <a
                    className="hover:underline focus:underline"
                    href={`tel:${profile.contactPhoneNumber}`}
                  >
                    {profile?.contactPhoneNumber}
                  </a>
                }
              />
            )}
            {profile?.contactEmail && (
              <ContactFieldWrapper
                icon={
                  <a
                    className="hover:underline focus:underline"
                    href={`mailto:${profile.contactEmail}`}
                  >
                    <EmailIcon />
                  </a>
                }
                copy={
                  <a
                    className="hover:underline focus:underline"
                    href={`mailto:${profile.contactEmail}`}
                  >
                    {profile.contactEmail}
                  </a>
                }
              />
            )}
          </div>
        </div>

        {socialMediaLinks.length >= 1 && (
          <div className="flex flex-col gap-y-1">
            <Typography as="p" size="t7">
              {t(`profilePage.profileCard.socialLinksHeading`)}
            </Typography>

            <SocialMediaLinks socialMediaLinks={socialMediaLinks} />
          </div>
        )}

        <div className="flex flex-col gap-y-1">
          <Typography as="p" size="t7">
            {t(`profilePage.profileCard.primaryLocationHeading`)}
          </Typography>
          <Typography as="p" size="bodySm">
            {profile?.city}, {profile?.country?.name}
          </Typography>
        </div>

        {hasSecondaryLocations && (
          <Locations
            locationsExpanded={locationsExpanded}
            secondaryLocations={secondaryLocations}
            setLocationsExpanded={setLocationsExpanded}
            t={t}
            locations={locations}
            hasMoreLocations={hasMoreLocations}
          />
        )}

        <Languages
          languagesExpanded={languagesExpanded}
          profile={profile}
          setLanguagesExpanded={setLanguagesExpanded}
          t={t}
          languages={languages}
          hasMoreLanguages={hasMoreLanguages}
        />
      </div>
    </Section>
  );
}
