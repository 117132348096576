import type {Profile} from '../../../../../types';

const SHOWN_LOCATIONS = 4;

const getLocations = (
  locationsExpanded: boolean,
  secondaryLocations: Profile['secondaryCountries'],
) => {
  return (
    locationsExpanded
      ? secondaryLocations
      : secondaryLocations.slice(0, SHOWN_LOCATIONS) ?? []
  )
    ?.map(({name}) => name)
    .join(`, `);
};

export default getLocations;
