import classNames from 'classnames';

interface Props {
  rating: number;
  inline?: boolean;
}

export function StarLine({rating, inline}: Props) {
  const className = classNames(
    `flex-auto`,
    inline ? `w-[1rem]` : `max-w-fit`,
    `pl-1`,
  );
  const starsArr = new Array(5).fill(0);
  return (
    <div className="flex items-center">
      {starsArr.map((_, index) => {
        if (index < rating) {
          return (
            <svg
              key={index}
              className={className}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.19974 15.9999C3.03414 15.9999 2.86934 15.9487 2.73014 15.8479C2.49014 15.6735 2.36533 15.3808 2.40774 15.0864L3.15094 9.88332L0.234122 6.96581C0.0221212 6.75382 -0.0530793 6.44183 0.0381213 6.15624C0.129322 5.87065 0.372523 5.66025 0.667725 5.61146L5.06615 4.87788L7.28376 0.442814C7.42056 0.170023 7.72057 -0.00997163 8.00377 0.000428047C8.30857 0.002028 8.58537 0.176423 8.71897 0.450814L10.8422 4.81228L15.3398 5.61306C15.6334 5.66505 15.8734 5.87625 15.963 6.16024C16.0526 6.44503 15.9766 6.75542 15.7654 6.96581L12.8478 9.88332L13.5918 15.0864C13.6334 15.3824 13.5086 15.6767 13.2654 15.8503C13.023 16.0247 12.703 16.0479 12.4382 15.9135L8.05577 13.6944L3.55414 15.9167C3.44214 15.9727 3.32054 15.9999 3.19974 15.9999Z"
                fill="#F5C452"
              />
            </svg>
          );
        } else {
          return (
            <svg
              key={index}
              className={`${className} pb-[2px]`}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.5018 10.7966L1.24008 8.0967C0.624455 7.60443 0.894465 6.61448 1.67569 6.50289L6.30186 6.2968L8.27744 1.34437C8.34471 1.20982 8.44812 1.09667 8.57609 1.01758C8.70406 0.938496 8.85153 0.896606 9.00196 0.896606C9.1524 0.896606 9.29987 0.938496 9.42784 1.01758C9.5558 1.09667 9.65922 1.20982 9.72649 1.34437L11.7021 6.2968L16.3282 6.50289C17.1095 6.61448 17.3795 7.60443 16.7638 8.0967L13.5021 10.7966L14.3923 16.0676C14.4155 16.2301 14.3938 16.396 14.3295 16.5471C14.2653 16.6982 14.1609 16.8289 14.0276 16.9249C13.8944 17.021 13.7374 17.0787 13.5737 17.0919C13.41 17.1052 13.2458 17.0733 13.0989 16.9999L9.00196 14.3964L4.90501 16.999C4.7581 17.0724 4.5939 17.1043 4.43019 17.091C4.26649 17.0778 4.10952 17.0201 3.97629 16.924C3.84307 16.828 3.73866 16.6973 3.67439 16.5462C3.61012 16.3951 3.58843 16.2292 3.61167 16.0667L4.5018 10.7966Z"
                fill="#D2D5D9"
              />
            </svg>
          );
        }
      })}
    </div>
  );
}
