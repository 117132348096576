import Typography from '@/components/base/elements/Typography/Typography';

interface Props {
  icon: React.ReactNode;
  copy: React.ReactNode;
}

const ContactFieldWrapper = ({icon, copy}: Props) => (
  <div className="flex flex-wrap gap-x-2 items-center">
    {icon}
    <Typography as="p" size="bodySm" className="break-word">
      {copy}
    </Typography>
  </div>
);

export default ContactFieldWrapper;
