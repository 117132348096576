export function EmailIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 5.85954V14C2 14.6627 2.53726 15.2 3.2 15.2H16.8C17.4627 15.2 18 14.6627 18 14V5.85947L10.403 10.291C10.1539 10.4363 9.84593 10.4363 9.59684 10.291L2 5.85954Z"
        fill="#87909B"
      />
      <path
        d="M17.5546 4.26693C17.3485 4.1 17.0859 4 16.8 4H3.2C2.91405 4 2.65146 4.10002 2.4453 4.26698L9.99994 8.67384L17.5546 4.26693Z"
        fill="#87909B"
      />
    </svg>
  );
}
