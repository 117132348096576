import {cva} from 'class-variance-authority';

export const containerStyles = 'max-w-xl md:max-w-2xl lg:max-w-3xl font-bold';

export const accordionStyles = cva(
  'grid py-4 border-b transition-all duration-[400ms] ease-[cubic-bezier(0.40,0.00,0.20,1.00)]',
  {
    variants: {
      mode: {
        light: 'text-black',
        dark: 'text-white border-white/30',
      },
      isExpanded: {
        true: 'grid-rows-[max-content_1fr]',
        false: 'grid-rows-[max-content_0fr]',
      },
    },
  },
);

export const buttonStyles = cva(
  'grid shrink-0 place-items-center w-6 h-6 md:w-6 md:h-6 ml-auto rounded-full transition-colors duration-500 delay-[50ms]',
  {
    variants: {
      isExpanded: {
        true: 'bg-transparent border-2 border-[#616A75]',
        false: '',
      },
      mode: {
        light: '',
        dark: '',
      },
    },
    compoundVariants: [
      {
        isExpanded: false,
        mode: 'light',
        class: 'bg-white border-2 border-[#616A75]',
      },
      {
        isExpanded: true,
        mode: 'light',
        class: 'border-2 border-[#616A75]',
      },
      {
        isExpanded: false,
        mode: 'light',
        class: 'bg-white border-2 border-[#616A75]',
      },
      {
        isExpanded: true,
        mode: 'light',
        class: 'border-2 border-[#616A75]',
      },
    ],
  },
);

export const panelStyles = cva(
  [
    'pr-8 md:pr-11 md:mr-sm overflow-hidden',
    'transition-opacity duration-[400ms] ease-[cubic-bezier(0.40,0.00,0.20,1.00)]',
    '[&_h4]:text-t7 [&_h4]:mb-2 [&_h4]:mt-md [&_h5]:mb-2 [&_h5]:mt-md [&_h5]:text-t8 [&_h6]:text-t8 [&_h6]:mb-2 [&_h6]:mt-md',
    containerStyles,
  ],
  {
    variants: {
      isExpanded: {
        true: 'opacity-100 delay-200',
        false: 'opacity-0 delay-0',
      },
    },
  },
);
