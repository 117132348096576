import type {ReactNode} from 'react';
import {useState} from 'react';

import {IconEnum} from '@/enums';
import Icon from '@/components/base/elements/Icon/Icon';
import Typography from '@/components/base/elements/Typography/Typography';

import {
  accordionStyles,
  containerStyles,
  buttonStyles,
  panelStyles,
} from './styles';

interface Props {
  content: ReactNode;
  heading: string;
  id: string;
}

export function Accordion({content, heading, id}: Props) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        setIsExpanded(!isExpanded);
      case 'Space':
        setIsExpanded(!isExpanded);
        break;
      default:
        break;
    }
  };

  return (
    <div
      className={accordionStyles({mode: `light`, isExpanded})}
      data-mode="light"
    >
      <button
        type="button"
        className="flex gap-x-sm place-items-center text-left justify-space-between"
        aria-expanded={isExpanded}
        aria-controls={`panel-${id}`}
        id={`header-${id}`}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        <Typography as="h3" className={containerStyles}>
          {heading}
        </Typography>
        <div
          className={buttonStyles({
            isExpanded,
            mode: isExpanded ? 'dark' : 'light',
          })}
        >
          <Icon
            className="w-4 h-4 text-[#616A75]"
            icon={IconEnum.ExpandCollapse}
            size={16}
            options={{isExpanded, mode: isExpanded ? `light` : `dark`}}
          />
        </div>
      </button>
      <div
        className={panelStyles({isExpanded})}
        id={`panel-${id}`}
        role="region"
        aria-labelledby={`header-${id}`}
      >
        <Typography as="div">{content}</Typography>
      </div>
    </div>
  );
}
