import {type SocialMediaLink} from '../../../types';

import {FacebookIcon} from './components/FacebookIcon/FacebookIcon';
import {InstagramIcon} from './components/InstagramIcon/InstagramIcon';
import {LinkedInIcon} from './components/LinkedInIcon/LinkedInIcon';
import {TwitterIcon} from './components/TwitterIcon/TwitterIcon';

interface Props {
  socialMedia: SocialMediaLink;
}

export function SocialMediaIcon({socialMedia: {type, url}}: Props) {
  const AnchorWrapper = ({children}: any) => (
    <a href={url} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
  switch (type) {
    case `FACEBOOK`:
      return (
        <AnchorWrapper>
          <FacebookIcon />
        </AnchorWrapper>
      );
    case `TWITTER`:
      return (
        <AnchorWrapper>
          <TwitterIcon />
        </AnchorWrapper>
      );
    case `LINKEDIN`:
      return (
        <AnchorWrapper>
          <LinkedInIcon />
        </AnchorWrapper>
      );
    case `INSTAGRAM`:
      return (
        <AnchorWrapper>
          <InstagramIcon />
        </AnchorWrapper>
      );
    default:
      return null;
  }
}
