export function PhoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect width="20" height="20" fill="white" />
      <path
        d="M8.77652 5.876L8.24252 3.206C8.10252 2.505 7.48652 2 6.77152 2H3.53852C2.67852 2 1.96252 2.727 2.00152 3.586C2.46252 13.747 7.50052 17.611 16.4165 17.999C17.2755 18.036 18.0005 17.323 18.0005 16.464V13.229C18.0005 12.514 17.4955 11.898 16.7945 11.758L14.1245 11.224C13.4615 11.091 12.7915 11.419 12.4885 12.024L12.0005 12.999C10.0005 12.999 7.00052 9.999 7.00052 7.999L7.97552 7.511C8.58152 7.209 8.90952 6.539 8.77652 5.876Z"
        fill="#87909B"
      />
    </svg>
  );
}
