export function WebsiteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.53426 18C5.32326 18 4.18226 17.528 3.32626 16.671C1.55726 14.903 1.55726 12.026 3.32626 10.257L5.29226 8.293C5.68226 7.902 6.31526 7.902 6.70626 8.293C7.09626 8.684 7.09626 9.317 6.70626 9.707L4.74026 11.671C3.75226 12.66 3.75226 14.269 4.74026 15.257C5.70126 16.216 7.37126 16.215 8.32726 15.257L10.2933 13.293C10.6833 12.902 11.3163 12.902 11.7083 13.293C12.0983 13.684 12.0983 14.317 11.7083 14.707L9.74226 16.671C8.88726 17.528 7.74626 18 6.53426 18ZM14.0011 12C13.7451 12 13.4891 11.902 13.2941 11.707C12.9031 11.316 12.9031 10.683 13.2941 10.293L15.2601 8.329C16.2481 7.34 16.2481 5.731 15.2601 4.743C14.2991 3.784 12.6291 3.786 11.6731 4.743L9.70706 6.707C9.31606 7.098 8.68406 7.098 8.29206 6.707C7.90106 6.316 7.90106 5.683 8.29206 5.293L10.2581 3.329C11.1131 2.472 12.2541 2 13.4661 2C14.6771 2 15.8171 2.472 16.6741 3.329C18.4421 5.097 18.4421 7.974 16.6741 9.743L14.7081 11.707C14.5131 11.902 14.2571 12 14.0011 12ZM7.99906 13C7.74306 13 7.48706 12.902 7.29206 12.707C6.90106 12.316 6.90106 11.684 7.29206 11.293L11.2931 7.293C11.6851 6.902 12.3171 6.902 12.7081 7.293C13.0991 7.684 13.0991 8.316 12.7081 8.707L8.70706 12.707C8.51206 12.902 8.25506 13 7.99906 13Z"
        fill="#87909B"
      />
    </svg>
  );
}
