export default function simplifyUrl(url: string): string {
  let simplifiedUrl;
  let fakeUrl;
  try {
    simplifiedUrl = new URL(url);
    fakeUrl = simplifiedUrl.hostname;
  } catch (_error) {
    fakeUrl = url;
  }

  if (/^www\./i.test(fakeUrl)) {
    return fakeUrl.substring(4);
  } else {
    return fakeUrl;
  }
}
