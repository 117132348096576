import {useState} from 'react';

import {StarLine} from '@/pages/shopify.com/($locale)/partners/directory/partner/$partner/components/StarLine/StarLine';
import Typography from '@/components/base/elements/Typography/Typography';
import {useSharedTranslations} from '@/hooks/useTranslations';
import {Skeleton} from '@/pages/shopify.com/($locale)/partners/directory/components/Skeleton/Skeleton';

import type {Review} from '../../../../types';

interface Props {
  review: Review;
  skeleton?: boolean;
}

export function Review({review, skeleton}: Props) {
  const {t} = useSharedTranslations('pages/partners/directory/shared');
  const [reviewExpanded, setReviewExpanded] = useState(false);

  const reviewDate = new Date(review.submittedAt).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const truncatedReviewText = review.text?.slice(0, 400);
  const reviewText = reviewExpanded ? review.text : `${truncatedReviewText}...`;
  const reviewerName =
    review?.reviewerName === `REDACTED`
      ? t(`profilePage.ratings.redactedReviewerName`)
      : review.reviewerName;

  const wrapperClassName =
    'flex flex-col gap-3 md:gap-4 pb-5 border-b border-subdued-light';

  if (skeleton) {
    return (
      <div
        className={wrapperClassName}
        data-component-name="profile-review-skeleton"
      >
        <div className="flex justify-between items-center">
          {[1, 2].map((i) => (
            <Skeleton type="text" className="w-[33%]" key={i} />
          ))}
        </div>

        <div className="flex flex-col gap-y-2">
          {[1, 2].map((i) => (
            <div className="flex justify-start items-center" key={i}>
              <Skeleton type="text" className="w-[7.25rem] mr-6" />
              <Skeleton type="text" className="w-[5rem] mr-1" />
              <Skeleton type="text" className="w-[1rem]" />
            </div>
          ))}
        </div>

        <div className="flex flex-col items-start gap-y-1">
          {[1, 2, 3, 4].map((i) => (
            <Skeleton type="text" key={i} className="w-full" />
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className={wrapperClassName} key={review.text}>
        <div className="flex justify-between items-center">
          <Typography as="h4" size="t6">
            {reviewerName}
          </Typography>
          <Typography
            as="p"
            size="body-sm"
            className="text-subdued hidden md:block"
          >
            {reviewDate}
          </Typography>
        </div>

        <div className="flex flex-col gap-y-2">
          <div className="flex items-center">
            <Typography as="p" size="t7" className="w-[7.25rem] mr-6">
              {t('profilePage.ratings.qualityHeading')}
            </Typography>
            <StarLine rating={review.qualityOfWorkRating} />
            <Typography as="span" size="t7" className="pl-2 opacity-70">
              {review.qualityOfWorkRating}
            </Typography>
          </div>
          <div className="flex items-center">
            <Typography as="p" size="t7" className="w-[7.25rem] mr-6">
              {t('profilePage.ratings.communicationHeading')}
            </Typography>
            <StarLine rating={review.communicationRating} />
            <Typography as="span" size="t7" className="pl-2 opacity-70">
              {review.communicationRating}
            </Typography>
          </div>
        </div>

        <pre className="font-sans whitespace-pre-wrap opacity-70 text-body-base">
          {review.text &&
            (review.text.length > 400 ? (
              <div className="flex flex-col items-start gap-y-1">
                {reviewText}
                <button
                  type="button"
                  className="underline opacity-70 font-normal hover:no-underline focus:outline-state-focus"
                  onClick={() => setReviewExpanded((prev) => !prev)}
                >
                  {reviewExpanded
                    ? t('profilePage.reviews.showLess')
                    : t('profilePage.reviews.showMore')}
                </button>
              </div>
            ) : (
              review.text
            ))}
        </pre>
        {review.service?.label && (
          <Typography as="p" size="body-sm" className="text-subdued">
            {t('profilePage.reviews.services', {
              service: review.service.label,
            })}
          </Typography>
        )}
      </div>
    );
  }
}
