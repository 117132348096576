export function Star({className}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.19974 17.9999C5.03414 17.9999 4.86934 17.9487 4.73014 17.8479C4.49014 17.6735 4.36533 17.3808 4.40774 17.0864L5.15094 11.8833L2.23412 8.96581C2.02212 8.75382 1.94692 8.44183 2.03812 8.15624C2.12932 7.87065 2.37252 7.66025 2.66772 7.61146L7.06615 6.87788L9.28376 2.44281C9.42056 2.17002 9.72057 1.99003 10.0038 2.00043C10.3086 2.00203 10.5854 2.17642 10.719 2.45081L12.8422 6.81228L17.3398 7.61306C17.6334 7.66505 17.8734 7.87625 17.963 8.16024C18.0526 8.44503 17.9766 8.75542 17.7654 8.96581L14.8478 11.8833L15.5918 17.0864C15.6334 17.3824 15.5086 17.6767 15.2654 17.8503C15.023 18.0247 14.703 18.0479 14.4382 17.9135L10.0558 15.6944L5.55414 17.9167C5.44214 17.9727 5.32054 17.9999 5.19974 17.9999Z"
        fill="#F5C452"
      />
    </svg>
  );
}
