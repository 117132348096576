import {RatingsBar} from '@/pages/shopify.com/($locale)/partners/directory/partner/$partner/components/RatingsBar/RatingsBar';
import {Star} from '@/pages/shopify.com/($locale)/partners/directory/partner/$partner/components/Star/Star';
import {StarLine} from '@/pages/shopify.com/($locale)/partners/directory/partner/$partner/components/StarLine/StarLine';
import Typography from '@/components/base/elements/Typography/Typography';
import {useSharedTranslations} from '@/hooks/useTranslations';
import {Tooltip} from '@/pages/shopify.com/($locale)/partners/directory/components/Tooltip/Tooltip';

import type {RatingBucket, Profile} from '../../../types';

interface Props {
  profile: Profile;
}

export function Rating({profile}: Props) {
  const {t} = useSharedTranslations('pages/partners/directory/shared');

  const ratings: RatingBucket[] = profile?.ratingBuckets;

  return (
    <div>
      <Typography as="h2" size="t5" className="flex items-center pb-4">
        <span>{t(`profilePage.ratings.heading`)}</span>
        <Star className="ml-2" />
        <span className="pl-1 text-body-lg font-normal">
          {Number(profile?.averageRating).toPrecision(2)}
        </span>
        <span className="pl-1 text-subdued text-body-lg font-normal">
          ({profile?.numberOfRatings})
        </span>
      </Typography>
      <Typography as="h3" size="t6" className="mb-3">
        {t(`profilePage.ratings.subheading`)}
      </Typography>
      <p className="text-sm text-subdued">
        {t(`profilePage.ratings.ratingsBased`)}&nbsp;
        <Tooltip
          handle="quality-of-work"
          buttonContent={
            <b className="underline decoration-dotted text-sm">
              {t(`profilePage.ratings.qualityOfWork`)}
            </b>
          }
          tooltipContent={t(`profilePage.ratings.qualityTooltip`)}
        />
        &nbsp;{t(`profilePage.ratings.and`)}&nbsp;
        <Tooltip
          handle="communication"
          buttonContent={
            <b className="underline decoration-dotted text-sm">
              {t(`profilePage.ratings.communication`)}
            </b>
          }
          reverseOnMobile
          tooltipContent={t(`profilePage.ratings.communicationTooltip`)}
        />
      </p>
      <div className="mt-3 flex">
        <ol>
          {ratings?.map((bucket) => (
            <li
              key={`${bucket.label}-label`}
              className="flex items-center h-4 mb-2"
            >
              <span
                aria-description={`${Number(bucket.label)} star ratings`}
                id={`${bucket.label}-label`}
                className="flex-auto max-w-fit"
              >
                <StarLine rating={Number(bucket.label)} />
              </span>
            </li>
          ))}
        </ol>
        <ol className="flex-1 ml-6 mr-2 mt-[1px]">
          {ratings?.map((bucket) => (
            <li
              aria-labelledby={`${bucket.label}-label`}
              className="h-4 mb-2"
              key={`${bucket.label}-bar`}
            >
              <RatingsBar
                percentage={Number(
                  (Number(bucket.numberOfRatings) /
                    Number(profile?.numberOfRatings)) *
                    100,
                )}
              />
            </li>
          ))}
        </ol>
        <ol className="mt-[-6px]">
          {ratings?.map((bucket) => (
            <li className="" key={`${bucket.label}-ratings`}>
              <span
                aria-labelledby={`${bucket.label}-label text-left`}
                className="text-gray-400 text-[14px] leading-[14px]"
              >
                ({bucket.numberOfRatings})
              </span>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
}
