export function SelectSVG() {
  return (
    <svg
      className="absolute z-10 top-4 right-3"
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.32374 4H0.676264C0.113017 4 -0.202493 3.39719 0.145493 2.98592L2.46923 0.239557C2.73949 -0.0798525 3.26051 -0.0798525 3.53077 0.239557L5.85451 2.98592C6.20249 3.39719 5.88698 4 5.32374 4Z"
        fill="#5C5F62"
      />
      <path
        d="M0.676263 6L5.32374 6C5.88698 6 6.20249 6.60281 5.85451 7.01408L3.53077 9.76044C3.26051 10.0799 2.73949 10.0799 2.46923 9.76044L0.145493 7.01408C-0.202494 6.60281 0.113017 6 0.676263 6Z"
        fill="#5C5F62"
      />
    </svg>
  );
}
