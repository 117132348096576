import Typography from '@/components/base/elements/Typography/Typography';
import {useSharedTranslations} from '@/hooks/useTranslations';

import {UserAvatarIcon} from '../../UserAvatarIcon/UserAvatarIcon';
import type {Profile} from '../../../../types';
import {Star} from '../../Star/Star';

interface Props {
  profile: Profile;
}

export function Ratings({profile}: Props) {
  const {t} = useSharedTranslations('pages/partners/directory/shared');

  return (
    <div className="flex flex-wrap self-stretch gap-x-1.5">
      <div className="flex items-center gap-x-1">
        <Star className="text-[#F5C452]" />
        <div className="flex gap-x-1">
          <p className="text-base font-medium">
            {Number(profile?.averageRating).toPrecision(2)}
          </p>
          <p className="text-subdued text-base">({profile?.numberOfRatings})</p>
        </div>
      </div>
      {profile?.averageRating &&
        profile?.numberOfRatings &&
        profile?.partnerSince && (
          <Typography as="span" className="text-shade-50">
            |
          </Typography>
        )}
      {profile?.partnerSince && (
        <div className="flex items-center gap-x-1">
          <UserAvatarIcon />
          <Typography as="p" size="bodySm">
            {t(`profilePage.profileCard.partnerSince`, {
              month: t(`months.${new Date(profile.partnerSince).getMonth()}`),
              year: new Date(profile.partnerSince).getFullYear(),
            })}
          </Typography>
        </div>
      )}
    </div>
  );
}
