import {useEffect, useRef, useState} from 'react';

import Section from '@/components/base/layouts/Section/Section';
import Typography from '@/components/base/elements/Typography/Typography';
import {useSharedTranslations} from '@/hooks/useTranslations';
import Pagination, {
  usePagination,
} from '@/components/base/modules/Pagination/Pagination';

import {
  FormIntent,
  type Profile,
  type Review as ReviewType,
} from '../../../types';
import {REVIEW_PAGE_SIZE} from '../../../../utilities/constants';

import {Review} from './components/Review';

interface Props {
  profile: Profile;
  fetcherReviews: ReviewType[] | undefined;
  submit: any;
  fetcherState: 'idle' | 'loading' | 'submitting';
}

interface State {
  loading: boolean;
  currentPage: number;
}

export function Reviews({
  profile,
  fetcherReviews,
  submit,
  fetcherState,
}: Props) {
  const {t} = useSharedTranslations('pages/partners/directory/shared');
  const scrollRef = useRef<HTMLDivElement>(null);
  const [{currentPage, loading}, setState] = useState<State>({
    currentPage: 1,
    loading: false,
  });

  const reviews = fetcherReviews?.length
    ? fetcherReviews
    : profile.ratings?.nodes;

  const paginationRange = usePagination({
    currentPage,
    totalCount: Number(profile.ratings.pageInfo.totalResults),
    siblingCount: 1,
    pageSize: REVIEW_PAGE_SIZE,
  });

  const handlePageClick = (page: number) => {
    setState({currentPage: page, loading: true});
    const after = page - 1;
    submit(
      {
        intent: FormIntent.FETCH_REVIEWS,
        after: after < 1 ? 0 : after * REVIEW_PAGE_SIZE,
      },
      {method: `POST`},
    );
    const [x, scrollRefY] = [0, scrollRef?.current?.offsetTop ?? NaN];
    const globalHeaderHeight =
      document?.querySelector<HTMLElement>(
        `header[data-component-name="global-nav"]`,
      )?.offsetHeight ?? NaN;
    const y = scrollRefY - globalHeaderHeight;
    window.scrollTo({
      left: x,
      top: Number.isNaN(y) ? 0 : y,
      behavior: `smooth`,
    });
  };

  /**
   * There is a noticable delay between the `submit` updating the fetcherState to `submitting`
   * or `loading` after firing, which causes a weird loading UX where nothing happens
   * for 1 - 2 seconds after clicking, so we're handled loading manually.
   */
  useEffect(() => {
    if (loading && fetcherState === `idle`) {
      setState((prevState) => ({...prevState, loading: false}));
    }
    // We don't care about when "loading" changes, only the fetcherState
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcherState]);

  return (
    <div ref={scrollRef}>
      <Section
        topSpacing="2xl"
        bottomSpacing="2xl"
        sectionName="reviews"
        className="pt-6 gap-5 border-t border-subdued-light"
      >
        <Typography as="h3" size="t5">
          {t('profilePage.reviews.heading')}
        </Typography>

        {reviews ? (
          <>
            {reviews.map((review, index) => (
              <Review
                review={review}
                skeleton={loading || fetcherState !== `idle`}
                key={`${review.reviewerName}-${index}`}
              />
            ))}
            <Pagination
              mode="light"
              currentPage={currentPage}
              onPageChange={handlePageClick}
              paginationRange={paginationRange}
            />
          </>
        ) : (
          <Typography as="p" size="body-base" className="text-subdued">
            {t('profilePage.reviews.noReviews')}
          </Typography>
        )}
      </Section>
    </div>
  );
}
