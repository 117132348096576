import cn from 'classnames';

import {useSharedTranslations} from '@/hooks/useTranslations';
import Typography from '@/components/base/elements/Typography/Typography';
import {ExternalLinkIcon} from '@/pages/shopify.com/($locale)/partners/directory/partner/$partner/components/ExternalLinkIcon/ExternalLinkIcon';

import type {FeaturedWork} from '../../types';
interface Props {
  featuredWorks: FeaturedWork[];
}

export function FeaturedWorks({featuredWorks}: Props) {
  const {t} = useSharedTranslations('pages/partners/directory/shared');
  return (
    <div className="pt-4">
      <Typography as="h2" size="t6">
        {t(`profilePage.featuredWork.heading`)}
      </Typography>
      {featuredWorks?.map?.((featuredWork, index) => (
        <div
          className={cn('flex flex-col', {
            'pt-6': index > 0,
            'pt-3': index === 0,
          })}
          key={featuredWork.title}
        >
          <Typography as="h3" className="font-bold">
            {featuredWork.title}
          </Typography>
          <pre className="py-1 font-sans whitespace-pre-wrap text-body-base opacity-70">
            {featuredWork?.description}
          </pre>
          {featuredWork?.url && (
            <a
              className="flex w-fit text-body-base text-[#2463BC] text-bold opacity-70 items-center underline hover:no-underline focus:outline-state-focus"
              href={featuredWork.url}
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              {t(`profilePage.featuredWork.link`)}
              <ExternalLinkIcon className="ml-1" />
            </a>
          )}
        </div>
      ))}
    </div>
  );
}
