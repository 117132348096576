import {NewBadge as BaseNewBadge} from '@/pages/shopify.com/($locale)/partners/directory/components/NewBadge/NewBadge';
import Typography from '@/components/base/elements/Typography/Typography';
import {useSharedTranslations} from '@/hooks/useTranslations';

import {UserAvatarIcon} from '../../UserAvatarIcon/UserAvatarIcon';
import type {Profile} from '../../../../types';

interface Props {
  profile: Profile;
}

export function NewBadge({profile}: Props) {
  const {t} = useSharedTranslations('pages/partners/directory/shared');
  return (
    <div>
      {profile?.partnerSince && (
        <div className="flex items-center gap-x-1 mb-4">
          <UserAvatarIcon />
          <p className="text-sm font-normal">
            {t(`profilePage.profileCard.newPartnerSince`, {
              month: t(`months.${new Date(profile.partnerSince).getMonth()}`),
              year: new Date(profile.partnerSince).getFullYear(),
            })}
          </p>
        </div>
      )}
      <div className="flex gap-x-1.5 items-center">
        <BaseNewBadge copy={t(`profilePage.profileCard.new`)} />
        {profile.acceptingClients && (
          <>
            <Typography as="span" className="text-shade-50">
              |
            </Typography>
            <span className="text-sm font-normal">
              {t(`profilePage.profileCard.openToWork`)}
            </span>
          </>
        )}
      </div>
    </div>
  );
}
