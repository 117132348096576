import {Accordion} from '@/pages/shopify.com/($locale)/partners/directory/partner/$partner/components/Accordion/Accordion';
import Typography from '@/components/base/elements/Typography/Typography';
import {useSharedTranslations} from '@/hooks/useTranslations';

import type {ServiceOffering} from '../../types';

interface Props {
  specializedServices: ServiceOffering[];
}

export function SpecializedServices({specializedServices}: Props) {
  const {t} = useSharedTranslations('pages/partners/directory/shared');

  const specializedServicePrice = (serviceOffering: ServiceOffering) => {
    const minPrice = serviceOffering?.pricingRange?.minPrice ?? NaN;
    const maxPrice = serviceOffering?.pricingRange?.maxPrice ?? NaN;
    const hasMinPrice = !Number.isNaN(minPrice);
    const hasMaxPrice = !Number.isNaN(maxPrice);

    if (hasMinPrice && hasMaxPrice) {
      return t(`profilePage.specializedServices.price`, {
        minPrice,
        maxPrice,
      });
    } else if (hasMinPrice && !hasMaxPrice) {
      return t(`profilePage.specializedServices.startingAt`, {minPrice});
    } else if (serviceOffering?.pricingType === `CONTACT`) {
      return t(`profilePage.specializedServices.contactForPricing`);
    } else {
      return null;
    }
  };

  return (
    <div className="pb-4">
      <Typography as="h2" size="t6" className="pt-4 pb-2">
        {t(`profilePage.specializedServices.heading`)}
      </Typography>
      {specializedServices.map((serviceOffering) => (
        <Accordion
          heading={serviceOffering.service.label}
          id={serviceOffering.service.handle}
          key={serviceOffering.service.handle}
          content={
            <>
              <Typography as="p" className="opacity-70 py-0.5 font-normal">
                {specializedServicePrice(serviceOffering)}
              </Typography>
              {serviceOffering?.description && (
                <pre className="font-normal text-body-base opacity-70 font-sans whitespace-pre-wrap">
                  {t(`profilePage.specializedServices.description`, {
                    description: serviceOffering?.description,
                  })}
                </pre>
              )}
            </>
          }
        />
      ))}
    </div>
  );
}
