import type {SocialMediaLink} from '../../../../../types';
import {SocialMediaIcon} from '../../../SocialMediaIcon/SocialMediaIcon';

interface Props {
  socialMediaLinks: SocialMediaLink[];
}

const SocialMediaLinks = ({socialMediaLinks}: Props) => (
  <div className="flex gap-x-3">
    {socialMediaLinks.map((socialMedia: any) => (
      <SocialMediaIcon key={socialMedia.type} socialMedia={socialMedia} />
    ))}
  </div>
);

export default SocialMediaLinks;
